import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';

export interface SiteConfigObj {
  id: number;
  ddoc_enable: boolean;
  ddoc_enable_feature_26: boolean;
  ddoc_enable_feature_28: boolean;
  timestamp_memo_enable: boolean;
  login_adfs_enable: boolean;
  maintenance_mode: boolean;
  maintenance_message_th: string;
  maintenance_message_en: string;
  ndid_enable: boolean;
  thaicom_enable: boolean;
  sms_otp_enable: boolean;
  email_otp_enable: boolean;
  ddoc_root_cert_company_name: string;
  self_service_form_enable: boolean;
  page_title: string;
  google_client_id?: string;
  export_memo_google_drive_enable: boolean;
  contract_setting: ContractSiteConfigObj;
}

export interface ContractSiteConfigObj {
  ddoc_enable: boolean;
  timestamp_memo_enable: boolean;
  ndid_enable: boolean;
  thaicom_enable: boolean;
  sms_otp_enable: boolean;
  email_otp_enable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  constructor(private http: ApiService) {}
  public getConfig() {
    // this api does not require authentication.
    return this.http.get(API_URL.site_config);
  }
  public getSessionPolicy() {
    return this.http.get('/api/session-policy/');
  }
}
